import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function VotingPage() {
    const navigate = useNavigate();

    useEffect(() => {
        const storedToken = sessionStorage.getItem('token');
        if (storedToken) {
            navigate('/urzad/glosowanie/form');
        }

        // Nasłuchiwanie wiadomości z tokenem po logowaniu
        window.addEventListener('message', (event) => {
            if (event.origin !== 'http://localhost:3000') return;
            const { token } = event.data;
            if (token) {
                sessionStorage.setItem('token', token);
                navigate('/urzad/glosowanie/form');
            }
        });
    }, [navigate]);

    const handleLogin = () => {
        window.open('http://localhost:5000/api/v1/auth/discord', 'DiscordLogin', 'width=600,height=600');
    };

    return (
        <div className="min-h-screen bg-gray-100 p-10">
            <h1 className="text-4xl">Logowanie przez Discord</h1>
            <p>Kliknij przycisk, aby zalogować się przez Discord i oddać głos.</p>
            <button onClick={handleLogin} className="mt-4 px-8 py-3 bg-blue-600 text-white">Zaloguj się przez Discord</button>
        </div>
    );
}

export default VotingPage;
