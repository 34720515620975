import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logoLightRP from '.././ikona_light.png'; // Ścieżka do Twojego logo
import Loader from "../Loader";

function Regulamin() {
    const [fadeText, setFadeText] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoaded(true);
        }, 999);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeText((prev) => !prev);
        }, 10000); // Miganie co 10 sekund

        return () => clearInterval(interval); // Czyszczenie po unmount
    }, []);


    if (!isLoaded) {
        return <Loader />;
    }

    return (
        <div className="min-h-screen bg-dark text-light p-10 relative">
            {/* Logo i napis Light RP z efektem */}
            <div className="absolute top-6 left-6 flex items-center">
                <img
                    src={logoLightRP}
                    alt="Light RP Logo"
                    className="w-16 h-16 animate-pulse drop-shadow-[0_0_15px_rgba(255,221,0,0.8)]"
                />
                <h1
                    className={`ml-4 text-2xl font-bold ${fadeText ? 'text-yellow-400 transition-colors duration-500' : 'text-white'
                        }`}
                >
                    Light RP
                </h1>
            </div>

            {/* Link do Strony Głównej */}
            <div className="absolute top-6 right-6">
                <Link to="/" className="px-8 py-3 bg-transparent border-2 border-light text-light font-bold rounded-full hover:bg-light hover:text-dark transition duration-300 ease-in-out transform hover:scale-105">
                    Strona Główna
                </Link>
            </div>

            {/* Regulamin */}
            <div className="container mx-auto mt-20">
                <h2 className="text-4xl font-bold text-yellow-400 mb-6">Słowniczek</h2>
                {/* Animowany neonowy pasek z cieniem */}
                <hr className="neon-gradient-shadow mb-6" />

                <div className="text-lg text-gray-300 space-y-4">
                    <h3 className="text-2xl font-bold text-white">Pojęcia Role Play</h3>
                    <p><span className="highlight-underline">IC <i>(In Character)</i></span> – Świat, w którym żyje nasza postać; kontent dotyczący bezpośrednio rozgrywki</p>
                    <p><span className="highlight-underline">OOC <i>(Out Of Character)</i></span> – Świat, w którym żyjemy my; wszystko co niezwiązane jest z rozgrywką</p>
                    <p><span className="highlight-underline">MG <i>(Meta Gaming</i>)</span> – Jest to wykorzystywanie informacji OOC w IC</p>
                    <p><span className="highlight-underline">LootRP</span> – jest to przeszukanie oraz zabranie itemów graczowi bez odegrania, bądź bez podłoża RP</p>
                    <p><span className="highlight-underline">WMG</span> – Wykorzystywanie Mechaniki Gry czyli na przykład coś co w prawdziwym życiu byłoby niemożliwe jak na przykład wsadzanie kogoś w ścianę kajdankami.</p>
                    <p><span className="highlight-underline">PG <i>(Power Gaming)</i></span> – definiuje się to jako zmuszanie kogoś do akcji RP. Przykład : /me uderza Jacka tak mocno, że stracił on przytomność</p>
                    <p><span className="highlight-underline">SS <i>(Stream Sniping</i></span> – Oglądanie danego streamera podczas rozgrywki na naszym serwerze i śledzenie go, jeżdżenie do niego etc</p>
                    <p><span className="highlight-underline">BW <i>(Brutally Wounded)</i></span> – Wprowadzenie postaci w stan nieprzytomności wskutek odniesionych obrażeń</p>
                    <p><span className="highlight-underline">VDM <i>(Vehicle Deathmatch)</i></span> – Jest to umyślne rozjeżdżanie osób np. specjalnie wjechanie w kogoś kto wysiada z pojazdu mając możliwość skręcenia w drugą stronę</p>
                    <p><span className="highlight-underline">RDM <i>(Random Deathmatch)</i></span> – Jest to umyślne strzelanie do osób bez głębszego powodu IC, które prowadzi do BW np. postrzelenie kogoś, bo powiedział do Ciebie „ty idioto”</p>
                    <p><span className="highlight-underline">CK <i>(Character Kill)</i></span> – Permanentne uśmiercenie postaci</p>
                    <p><span className="highlight-underline">FCK <i>(Forced Character Kill)</i></span> – Wymuszone uśmiercenie postaci przez osobę inną, niż właściciel postaci</p>
                    <p><span className="highlight-underline">CL <i>(Combat Log)</i></span> – Wyjście z gry podczas akcji RP. Przykładowo: ściga Cię policja, więc robisz ALT+F4 w celu uniknięcia odpowiedzialności</p>
                    <p><span className="highlight-underline">Zasada Szanowania Życia</span> – zasada mówiąca o szacunku do życia swojej postaci i nie narażanie jej na sytuacje, w których mogłaby zginąć, np. obraza kilku uzbrojonych osób lub taranowanie ciężkich pojazdów typu ciężarówka zwykłym samochodem osobowym</p>
                    <p><span className="highlight-underline">Fail RP</span> – błędne odegranie akcji RP, wychodzenie z postaci*</p>
                    <p><span className="highlight-underline">Wychodzenie z Postaci</span> – sytuacja, kiedy podczas akcji RP wychodzisz z IC do OOC krzycząc do kogoś np. łamiesz regulamin! Unikaj takich zachowań, a wszelkie sytuacje, w których według Ciebie złamany został regulamin, zgłoś do administracji</p>
                    <p><span className="highlight-underline">Fail Driving</span> – jazda pojazdem, która byłaby niemożliwa w prawdziwym życiu. Przykład: jedziesz samochodem, po czym wyskakujesz na wzniesieniu, lecisz w powietrzu 100 metrów, dachujesz, końcowo lądujesz na kołach i jedziesz dalej lub wyskakujesz z ulicy do kanałów burzowych</p>
                    <p><span className="highlight-underline">GZ <i>(Green Zone)</i></span> – Tereny komend, szpitali, Mechanik, centrum egzaminacyjnego (prawo jazdy), Siedziba psychologa, miejsca prac legalnych, Car dealer, Sklepy odzieżowe, Salon fryzjerski, Urząd miasta.</p>
                    <p><span className="highlight-underline">Red Zone</span> – Zbiórka i przeróbka narkotyków, pralnia, siedziby organizacji przestępczych.</p>
                    <p><span className="highlight-underline">RK <i>(Revenge Kill)</i></span> – Zemsta na osobie, która wcześniej doprowadziła nas do BW. Po odrodzeniu się na szpitalu nasza postać nie pamięta, dlaczego się na nim znalazła.</p>
                    <p><span className="highlight-underline">NJ <i>(Ninja Jacking)</i></span> – Wyrzucenie kierowcy z pojazdu bez poprawnego odegrania akcji RP przy użyciu komend narracyjnych.</p>
                    <p><span className="highlight-underline">GB <i>(Gang Baiting)</i></span> – Podjeżdżanie/prowokowanie osób przynależnych do gangów. Jeżdżenie przy ich ośkach w celu wymuszenia na nich reakcji bez większego celu.</p>
                    <p><span className="highlight-underline">CB <i>(Cop Baiting)</i></span> – Prowokowanie policji, bez większego celu</p>
                    <p><span className="highlight-underline">Dzwon RP</span> – Celowe, wielokrotne uderzanie samochodu innego gracza aby jego pojazd tracił co chwilę kontrolę</p>

                    <hr className="neon-gradient-shadow mb-6" />

                    <h3 className="text-2xl font-bold text-white mt-6">Zakazane powiedzonka słówka</h3>
                    <ul className="list-disc pl-6">
                        <li>Leżysz to leż</li>
                        <li>Bombimy się</li>
                        <li>Kroje Fanty</li>
                        <li>Dostał z Pierwszej</li>
                        <li>Wbita</li>
                        <li>Bitka</li>
                        <li>Ja za 2 itp</li>
                        <li>Borowik</li>
                        <li>Lotnia</li>
                        <li>Do Zera</li>
                        <li>Mierniaki</li>
                        <li>Po Body</li>
                        <li>Klikamy ich</li>
                        <li>Dajcie mi ostatniego</li>
                        <li>Ale pestka</li>
                        <li>Chyba nie ma gatki</li>
                        <li>Kup se ręce</li>
                        <li>Bez oporu</li>
                        <li>Opór, Pyrra Yalla</li>
                        <li>Duelek</li>
                        <li>Leży jeden,drugi,trzeci itd. skidibi</li>
                        <li>Za zero</li>
                        <li>Essa, essunia itp.</li>
                        <li>łatwo, łatwiutko</li>
                    </ul>

                    <hr className="neon-gradient-shadow mb-6" />
                </div>
            </div>
        </div>
    );
}

export default Regulamin;
