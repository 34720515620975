import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoLightAPI from './light-api-favicon-white.png';
import Loader from "../Loader";
import { FaServer, FaSyncAlt, FaExclamationTriangle, FaDatabase, FaArrowRight, FaDev, FaChromecast, FaLayerGroup } from 'react-icons/fa';

function APIInfoPolish() {
    const [fadeText, setFadeText] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [pingTime, setPingTime] = useState(null);
    const [error, setError] = useState(false);
    const [cooldown, setCooldown] = useState(false);
    const [cooldownTimeLeft, setCooldownTimeLeft] = useState(0); // State to hold remaining cooldown time
    const [totalRequests, setTotalRequests] = useState(0); // State to hold total requests

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoaded(true);
        }, 999);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeText((prev) => !prev);
        }, 10000);

        return () => clearInterval(interval);
    }, []);

    // Fetch API ping
    const fetchPing = async () => {
        try {
            const startTime = new Date().getTime();
            const response = await fetch('http://localhost:5000/api/v1/ping'); // Correct endpoint
            const endTime = new Date().getTime();
            if (response.ok) {
                const ping = endTime - startTime;
                setPingTime(ping);
                setError(false);
            } else {
                throw new Error('Ping error');
            }
        } catch (error) {
            setError(true);
            setPingTime('Error');
        }
    };

    // Fetch total requests from backend
    const fetchTotalRequests = async () => {
        try {
            const response = await fetch('http://localhost:5000/api/v1/total-requests'); // Correct endpoint
            const data = await response.json();
            setTotalRequests(data.totalRequests);
        } catch (error) {
            console.error('Error fetching total requests:', error);
        }
    };

    // Check for cooldown on page load
    useEffect(() => {
        fetchPing();
        fetchTotalRequests(); // Fetch immediately on load
        const interval = setInterval(fetchPing, 5000); // Fetch ping every 5 seconds automatically

        // Check cooldown from sessionStorage
        const storedCooldownTime = sessionStorage.getItem('cooldownTime');
        if (storedCooldownTime) {
            const remainingTime = new Date(storedCooldownTime).getTime() - Date.now();
            if (remainingTime > 0) {
                setCooldown(true);
                setCooldownTimeLeft(Math.floor(remainingTime / 1000)); // Convert milliseconds to seconds
            }
        }

        return () => clearInterval(interval);
    }, []);

    // Update the cooldown time countdown every second
    useEffect(() => {
        let countdownInterval;
        if (cooldown && cooldownTimeLeft > 0) {
            countdownInterval = setInterval(() => {
                setCooldownTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(countdownInterval);
                        setCooldown(false);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }
        return () => clearInterval(countdownInterval);
    }, [cooldown, cooldownTimeLeft]);

    // Function to handle "Przetestuj mnie!" button click
    const handleTest = async () => {
        const lastTestTime = sessionStorage.getItem('lastTestTime');
        const currentTime = Date.now();

        // Cooldown for 6 hours
        const cooldownDuration = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
        if (lastTestTime && currentTime - new Date(lastTestTime).getTime() < cooldownDuration) {
            toast.warn('Musisz odczekać 6 godzin przed kolejnym testem!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        // Send a POST request to the backend
        try {
            const response = await fetch('http://localhost:5000/api/v1/send-message', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    message: 'Test message from Light API', // Example message
                }),
            });

            if (response.ok) {
                toast.success('Wiadomość została wysłana!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                const cooldownExpiryTime = new Date(currentTime + cooldownDuration);
                sessionStorage.setItem('lastTestTime', new Date().toISOString()); // Save the current time
                sessionStorage.setItem('cooldownTime', cooldownExpiryTime.toISOString()); // Save cooldown expiry
                setCooldown(true);
                setCooldownTimeLeft(cooldownDuration / 1000); // Set cooldown in seconds
            } else {
                toast.error('Błąd podczas wysyłania wiadomości.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error('Error sending message:', error);
            toast.error('Błąd podczas wysyłania wiadomości.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    // Format the cooldown time into hours, minutes, and seconds
    const formatCooldownTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = timeInSeconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    if (!isLoaded) {
        return <Loader />;
    }

    return (
        <div className="min-h-screen bg-gradient-to-r from-white to-gray-100 text-gray-900 p-10 relative">
            <ToastContainer /> {/* ToastContainer renders the toasts */}

            {/* Logo and title */}
            <div className="absolute top-6 left-6 flex items-center">
                <img
                    src={logoLightAPI}
                    alt="Light API Logo"
                    className="w-16 h-16"
                />
                <h1
                    className={`ml-4 text-3xl font-extrabold ${fadeText ? 'text-gray-700 transition-colors duration-500' : 'text-black'}`}
                >
                    Light API
                </h1>
            </div>

            {/* Test Button */}
            <div className="absolute top-6 right-6">
                <button
                    onClick={handleTest}
                    className="px-8 py-3 bg-transparent border-2 border-gray-900 text-gray-900 font-bold rounded-full hover:bg-gray-900 hover:text-white transition duration-300 ease-in-out transform hover:scale-105"
                    disabled={cooldown} // Disable button if cooldown is active
                >
                    {cooldown ? `Odczekaj ${formatCooldownTime(cooldownTimeLeft)}` : 'Przetestuj mnie!'}
                </button>
            </div>

            {/* API Information Section */}
            <div className="container mx-auto mt-20">
                <h2 className="text-4xl font-bold text-gray-700 mb-6">Informacje o API</h2> <p className="text-2xl font-bold text-red-500"><FaExclamationTriangle className="inline mr-2" /> Uwaga: Przerwa techniczna</p>
                <hr className="mb-6 border-gray-300" />

                <div className="text-lg text-gray-600 space-y-10">
                    <div className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105 hover:shadow-lg">
                        <h3 className="text-2xl font-bold text-gray-900 mb-2">
                            <FaServer className="inline mr-2 text-blue-500 text-2xl" />
                            Czym jest Light API?
                        </h3>
                        <p>
                            Light API to najszybszy oraz najbezpieczniejszy sposób na przesyłanie danych z panelu administracyjnego do bota na Discord.
                        </p>
                        <Link to="/api-docs" className="inline-block mt-4 text-blue-600 hover:text-blue-800 font-semibold transition duration-300 ease-in-out">
                            <FaArrowRight className="inline mr-2" /> Zobacz dokumentację
                        </Link>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105 hover:shadow-lg">
                        <h3 className="text-2xl font-bold text-gray-900 mb-2">
                            <FaChromecast className="inline mr-2 text-green-500 text-2xl" />
                            Jak działa?
                        </h3>
                        <p>
                            Frontend zbiera dane przez formularz, które są następnie przesyłane do backendu, a potem do bota na Discord.
                        </p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105 hover:shadow-lg">
                        <h3 className="text-2xl font-bold text-gray-900 mb-2">
                            <FaSyncAlt className="inline mr-2 text-yellow-500 text-2xl" />
                            Ping API - Aktualizacja co 5 sekund
                        </h3>
                        <p>
                            Poniżej znajduje się rzeczywisty czas pingowania serwera API.
                        </p>
                        <div className="bg-gray-200 p-4 rounded-lg shadow-inner mt-4">
                            <h4 className="text-xl font-semibold">Czas Ping API:</h4>
                            {error ? (
                                <p className="text-2xl font-bold text-red-500">
                                    <FaExclamationTriangle className="inline mr-2" /> Błąd: Brak odpowiedzi
                                </p>
                            ) : (
                                <p className="text-2xl font-bold">
                                    {pingTime !== null ? `${pingTime} ms` : 'Ładowanie...'}
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105 hover:shadow-lg">
                        <h3 className="text-2xl font-bold text-gray-900 mb-2">
                            <FaLayerGroup className="inline mr-2 text-orange-500 text-2xl" />
                            Łącznie wykonano żądań
                        </h3>
                        <p className="text-3xl font-semibold">{totalRequests}</p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105 hover:shadow-lg">
                        <h3 className="text-2xl font-bold text-gray-900 mb-2">
                            <FaDatabase className="inline mr-2 text-purple-500 text-2xl" />
                            Dlaczego warto używać Light API?
                        </h3>
                        <p>
                            Light API pozwala na dostosowanie do każdego zadania, a dodatkowo nie ma ograniczeń typu rate limit.
                        </p>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md transition-transform hover:scale-105 hover:shadow-lg">
                        <h3 className="text-2xl font-bold text-gray-900 mb-2">
                            <FaDev className="inline mr-2 text-cyan-500 text-2xl" />
                            Developer Light API
                        </h3>
                        <p>
                            Głównym developerem jest vi_vio (Owner Light RP), wspierany przez zespół developerski.
                        </p>
                    </div>
                </div>

                <hr className="mt-10 border-gray-300" />
            </div>
        </div>
    );
}

export default APIInfoPolish;
