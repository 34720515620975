import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logoLightRP from '.././ikona_light.png'; // Ścieżka do Twojego logo
import Loader from ".././Loader";

function Regulamin() {
  const [fadeText, setFadeText] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 999);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeText((prev) => !prev);
    }, 10000); // Miganie co 10 sekund

    return () => clearInterval(interval); // Czyszczenie po unmount
  }, []);


  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div className="min-h-screen bg-dark text-light p-10 relative">
      {/* Logo i napis Light RP z efektem */}
      <div className="absolute top-6 left-6 flex items-center">
        <img
          src={logoLightRP}
          alt="Light RP Logo"
          className="w-16 h-16 animate-pulse drop-shadow-[0_0_15px_rgba(255,221,0,0.8)]"
        />
        <h1
          className={`ml-4 text-2xl font-bold ${fadeText ? 'text-yellow-400 transition-colors duration-500' : 'text-white'
            }`}
        >
          Light RP
        </h1>
      </div>

      {/* Link do Strony Głównej */}
      <div className="absolute top-6 right-6">
        <Link to="/" className="px-8 py-3 bg-transparent border-2 border-light text-light font-bold rounded-full hover:bg-light hover:text-dark transition duration-300 ease-in-out transform hover:scale-105">
          Strona Główna
        </Link>
      </div>

      {/* Regulamin */}
      <div className="container mx-auto mt-20">
        <h2 className="text-4xl font-bold text-yellow-400 mb-6">Regulamin</h2>
        {/* Animowany neonowy pasek z cieniem */}
        <hr className="neon-gradient-shadow mb-6" />

        <div className="text-lg text-gray-300 space-y-4">
          <h3 className="text-2xl font-bold text-white">§1. POSTANOWIENIA OGÓLNE</h3>
          <p>1.1 Nieznajomość regulaminu nie zwalnia z jego przestrzegania.</p>
          <p>1.2 Każda osoba wchodząca na serwer akceptuje regulamin i zobowiązuję się do przestrzegania go.</p>
          <p>1.3 Każdy gracz jest zobowiązany do wykonywania poleceń administracji.</p>
          <p>1.4 Okłamywanie administracji jest surowo zakazane.</p>
          <p>1.5 Administracja ma prawo karać za czyny nie uwzględnione w regulaminie, działające na szkodę serwera jeśli uzna to za słuszne.</p>
          <p>1.6 Każdy gracz ma obowiązek posiadać taki sam nick w dowodzie jak i na roblox.</p>
          <p>1.7 Administracja zastrzega sobie prawo do modyfikacji regulaminu, gdy uzna to za słuszne.</p>
          <p>1.8 Rozgrywka na serwerze Light RolePlay jest zgodna z regulaminami platform służących do streamowania (np. Twitch) </p>
          <p>1.9 Regulamin serwera jest najważniejszym dokumentem i żaden regulamin (frakcji, organizacji przestępczej lub biznesu) nie może być sprzeczny z regulaminem głównym. W przypadku sprzeczności respektowane są zapisy z regulaminu głównego.</p>
          <p>1.10 Od niektórych zapisów z regulaminu mogą wystąpić wyjątki za zgodą administracji.</p>
          <p>1.11 Administrator ma decydujący głos we wszystkich sprawach dotyczących serwera.</p>
          <strong><p>1.12 Regulamin serwera discord określa Terms of Service Discord Terms of Service | Discord  oraz :</p></strong>
          <ul className="list-disc pl-6">
            <li>1.12.1 Zakaz spamowania, używania nadmiernego caps locka.</li>
            <li>1.12.2 Nakazuje się być kulturalnym oraz nie używać słów wulgarnych, obraźliwych lub poniżających.</li>
            <li>1.12.3 Zakazuje się propagowania poglądów nawołujących do nienawiści, nazizmu, faszyzmu itp.</li>
            <li>1.12.4 Zakazuje się podawania wrażliwych informacji o osobach trzecich.</li>
            <li>1.12.5 Zakazuje się wysyłania treści NSFW.</li>
            <li>1.12.6 Zakazuje się wysłania treści NSFW itp.</li>
            <li>1.12.7 Zakazuje się jakiegokolwiek podszywania,zbierania lub podawania wrażliwych infromacji o osobach trzecich. </li>
          </ul>

          <hr className="neon-gradient-shadow mb-6" />

          <h3 className="text-2xl font-bold text-white mt-6">§2. ZASADY OGÓLNE</h3>
          <p>2.1 Zakazuje się wychodzenia z serwera podczas pełnionej akcji RolePlay.</p>
          <p>2.2 W przypadku gdy dostaniesz crasha, stracisz dostęp do internetu lub prądu itp. jesteś zobowiązany poinformować o tym na odpowiednim kanale discord i jak najszybciej wrócić do akcji i ją kontynuować.</p>
          <p>2.3 Zakazuje się wykorzystywania mechaniki gry aby uzyskać z tego korzyści.</p>
          <p>2.4 Zakazuje się przebywania na transmisji live z naszego serwera podczas pełnionej rozgrywki na serwerze.</p>
          <ul className="list-disc pl-6">
            <li>2.4.1 Zakazuje się prowadzenia transmisji live podczas zdawania licencji na broń oraz nagrywania bądź udostępniania pytań zadawanych przez psychologa.</li>
          </ul>
          <p>2.5 Każdy gracz grający na serwerze ma obowiązek posiadać sprawny mikrofon i aktywnie się przez niego komunikować.</p>
          <p>2.6 Na serwerze można odgrywać ERP i BRP (EroticRP oraz BrutalRP), po spełnieniu poniższych waunków (nie łamiących ToS Roblox):</p>
          <ul className="list-disc pl-6">
            <li>2.6.1 Obustronna zgoda OOC.</li>
            <li>2.6.2 Znalezienie miejsca odosobnionego od graczy nie biorących udziału w akcji.</li>
          </ul>
          <p>2.7 Tylko administrator ma prawo do przerwania akcji.</p>
          <p>2.8 Bycie pod wpływem jakichkolwiek substancji nie zwalnia cię z przestrzegania regulaminu.</p>
          <p>2.9 Gracz ma obowiązek odegrać akcje poprawnie, nawet jeśli drugi gracz łamie regulamin. </p>
          <p>2.10 Wszelkie złamanie regulaminu należy zgłosić administracji poprzez odpowiedni do tego kanał na serwerze discord.</p>
          <p>2.11 Na wezwanie rządowego masz 24h od momentu zakończenia akcji.</p>
          <p>2.12 Administrator ma prawo ukarać gracza, który bezpodstawnie wezwał rządowy.</p>
          <p>2.13 Zakazuje się obrażania lub reklamowania innych serwerów.</p>
          <p>2.14 Po każdym restarcie serwera (tzn. wywalenie się z rowerka ER:LC) każdy gracz ma okres 3 minut nietykalności (zapis nie dotyczy osób, które przed restartem brały udział w akcji).</p>
          <p>2.15 Rozgrywka toczy się w San Francisco - stan California (odpowiednik San Francisco [USA] w świecie Roblox czyt. ER:LC).</p>
          <p>2.16 W przypadku braku konkretnego podpunktu regulaminu stosuje się podstawowe zasady RP (takie jak zakaz LootRP itp.)</p>

          <hr className="neon-gradient-shadow mb-6" />

          <h3 className="text-2xl font-bold text-white mt-6">§3. KOMUNIKACJA</h3>
          <p>3.1 Do prowadzenia narracji podczas rozgrywki służą komendy /me oraz /do i /try opisane w dalszej części regulaminu. Pamiętaj – zawsze staraj się odgrywać głosowo. Komenda /me ma być tylko dodatkiem, który pomoże wczuć Ci się w świat gry. Każde przeszukanie musi być odegrane poprzez komendy narracyjne!</p>
          <p>3.2 Używając komendy /do nie możesz kłamać!</p>
          <p>3.3 Każdy gracz ma obowiązek używać komend narracyjnych zgodnie z ich przeznaczeniem.</p>
          <p>3.4 Każdy gracz ma prawo do używania modulatora, po wcześniejszym uzgodnieniu tego z administracją.</p>
          <p>3.5 Zakazuje się używania metazwrotów.</p>
          <p>3.6 Zakazuje się odgrywania myśli poprzez komendy narracyjne.</p>
          <p>3.7 Każde przeszukanie musi zostać odegrane za pomocą komend /me oraz /do lub również /try. </p>
          <ul className="list-disc pl-6">
            <li>3.7.1 Zakazuje się przeszukiwania przez odgrywanie “/do widzi all?” itp.</li>
          </ul>
          <p>3.8 Zakazuje się wykorzystania nicków OOC jako “ksywek”.</p>
          <p>3.9 Zakazuje się przedłużania akcji przeszukiwania chowając przedmioty w różnych dziwnych miejscach.</p>

          <hr className="neon-gradient-shadow mb-6" />

          <h3 className="text-2xl font-bold text-white mt-6">§4. ROZGRYWKA</h3>
          <p>4.1 Zakazuje się odradzania na BW jeśli akcja jest dalej kontynuowana. Wszelkie takie odrodzenia będą traktowane jako unikanie akcji RP.</p>
          <p>4.2 Zakazuje się samodzielnego odgrywania poważnych operacji (np. wyciągania kuli)</p>
          <p>4.3 Każdy gracz zobowiązany jest do odgrywania bólu i strachu, adekwatnie do sytuacji.</p>
          <p>4.4 Po udzieleniu pierwszej pomocy (operacja lub lokalny medyk) przez 10 minut nie możesz wykonywać czynności która mogłaby spowodować uszczerbek na Twoim zdrowiu, masz obowiązek odgrywać rannego. Zakazuje się również całkowitego powrotu do akcji przez, którą byłeś w szpitalu.</p>
          <p>4.5 Zakazuje się wjeżdżania w innych graczy, nawet jeśli masz do tego inicjacje (zakaz VDM).</p>
          <p>4.6 Postrzał w kamizelkę można odgrywać, jeśli posiadamy na sobie kamizelkę (ubiór).</p>
          <p>4.7 Zakazuje się grania w tak zwanego „Dzwon RP”.</p>
          <p>4.8 Aby nagranie było traktowane jako IC trzeba wszystko poprawnie odegrać.</p>
          <p>4.9 Zakazuje się uciekania na tereny greenzone podczas agresywnej akcji RP. (Greenzone rozpisane w dalszej części regulaminu).</p>
          <p>4.10 Prowokowanie na GZ zezwala na kontynuowanie akcji RP i doprowadzenie prowokującego do bw.</p>
          <p>4.11 Zakazuje się Gang Baitingu oraz Cop Baitingu (definicja na dole regulaminu).</p>
          <p>4.12 Tereny Green Zone to : Szpital, Lotnisko (Główny Spawn), Komedny wszystkich departamentów (wyjątki : eventy, akcje za zgodą zarządu serwera).</p>
          <p>4.13 Teren Red Zone : Siedziby wszystkich organizacji przestępczych zaakceptowanych przez opiekuna crime do 50m.</p>

          <hr className="neon-gradient-shadow mb-6" />

          <h3 className="text-2xl font-bold text-white mt-6">§5. POSTAĆ</h3>
          <p>5.1 Niedozwolone jest posiadanie imienia i nazwiska znanej osoby, bądź posiadanie polskojęzycznych danych lub takich, które brzmią polsko oraz mogą być interpretowane jako związane z polskimi wyrażeniami, a w szczególności takich, które mogą być uznane za nieodpowiednie, obraźliwe lub prowokacyjne w każdym języku. (Występują wyjątki od tej zasady po wcześniejszym kontakcie z zarządem serwera Light RolePlay).</p>
          <p>5.2 Zakazuje się noszenia na sobie obraźliwych ubrań oraz ubierania hełmów/kasków o działaniu kuloodpornym.</p>
          <p>5.2.1 Zakazuje się noszenia ubrań frakcyjnych nie będąc jej członkiem.</p>
          <p>5.3 Po obudzeniu się w szpitalu nie pamiętasz ostatniej akcji przez, którą do niego trafiłeś.</p>
          <p>5.4 Każdy gracz ma prawo do CK, jeśli wynika ono z wątku IC i zostało poprawie odegrane. CK innemu graczowi można zrobić po uzgodnieniu tego z administracją.</p>
          <p>5.5 Każdy gracz ma obowiązek posiadać przy sobie dowód.</p>
          <p>5.6 Zakazuje się przenoszenia wszelkich informacji, majątku, sugerowania się informacjami, jakichkolwiek znajomości pomiędzy postaciami gracza oraz przekazywania i przepisywania majątku przed planowanym ck.</p>
          <p>5.7 Jeżeli ktoś ma maskę i moduluje swój głos nie można go rozpoznać.</p>
          <p>5.8 Zakazuje się posiadania takiej samej lub podobnej ksywki IC jak nicku OOC, poza przypadkami kiedy ma to jakiekolwiek odniesienie IC.</p>
          <p>5.9 Osobę chorą psychicznie można odgrywać po uzgodnieniu tego z administracją.</p>
          <p>5.10 Zakaz symulowania choroby psychicznej.</p>
          <p>5.11 Zakaz noszenia masek/czapek zwiększających obwód głowy.</p>
          <p>5.12 Jeden gracz może posiadać maksymalnie dwa mieszkania.</p>

          <hr className="neon-gradient-shadow mb-6" />

          <h3 className="text-2xl font-bold text-white mt-6">§6. POJAZDY ORAZ PRZEDMIOTY</h3>
          <p>6.1 Zakazuje się posiadania rzeczy powiązanych z m.in SFFD, SFMTA, SFPD, NPS: odznak, GPS, mundurów i innych uniformów, naprawek blacharskich itp. jako cywil.</p>
          <p>6.3 Posiadanie przedmiotu skryptowo w ekwipunku obliguje do odgrywania posiadania przedmiotu IC.</p>
          <p>6.4 Zakazuje się odgrywania chowania lub wyrzucania przedmiotów będąc w stanie BW.</p>
          <p>6.5 Zakazuje się oszukiwania podczas sprzedaży auta/nieruchomości. </p>
          <p>6.8 Wyciąganie oraz chowanie broni długiej należy odegrać przy użyciu komend narracyjnych, jeżeli nie posiadamy torby lub plecaka adekwatnych do wymiarów broni.</p>
          <p>6.9 Zakazuje się sprzedaży/wymian aut limitowanych oraz posiadłości/apartamentów kupionych za pomocą dotacji. Sprzedaż skutkuje usunięciem przedmiotu ze świata gry oraz banem dla sprzedającego.</p>
          <p>6.13 Maksymalny rabat przy sprzedaży pojazdu innej osobie to -20% od ceny bazowej(Przykład: Jeśli auto kosztuje 5mln to minimalna cena sprzedaży to 4mln). – Nieprzestrzeganie = ban za niszczenie ekonomii serwera.</p>
          <p>6.14 Jeśli dany pojazd fizycznie nie posiada tablic rejestracyjnych to dalej traktowany jest jako pojazd z tylną tablicą rejestracyjną.</p>
          <p>6.16 Aby pojazd mógł poruszać się po nieutwardzonym terenie musi posiadać offroadowe opony oraz odpowiednio wysokie zawieszenie lub jeździć poniżej 50km/h.</p>

          <hr className="neon-gradient-shadow mb-6" />

          <h3 className="text-2xl font-bold text-white mt-6">§7. FRAKCJE</h3>
          <p>7.1 Urząd Miasta zarządza budżetem każdego z departamentu.</p>
          <p>7.1 Administracja ma prawo do zmiany lidera frakcji bez podania konkretnego powodu.</p>
          <p>7.2 Służby publiczne mają prawo do PowerGamingu w celu przeniesienia, skuwania gracza itd.</p>
          <p>7.3 Zgodę na bycie skorumpowanym wydaje administracja. Bez takiej zgody zakazuje się odgrywania postaci skorumpowanej. Każdy członek frakcji może ubiegać się o postać skorumpowaną.</p>
          <p>7.4 Po zwolnieniu z frakcji, można dołączyć do niej znowu po 7 dniach chyba że, posiada się tak zwaną blacklistę.</p>
          <p>7.5 Zakazuje się wynoszenia przedmiotów i/lub broni należących do frakcji.</p>
          <p>7.6 Zakazuje się kradzieży aut EMS oraz innych departamentów bez większego powodu IC.</p>
          <p>7.7 AMR ma prawo do Power Gamingu jeśli: </p>
          <ul className="list-disc pl-6">
            <li>7.7.1 Poszkodowany nie bierze czynnego udziału w akcji (np. jest AFK) </li>
            <li>7.7.2 Nie blokuje to wyborów postaciowych poszkodowanego </li>
            <li>7.7.3 Dostanie zgodę od poszkodowanego </li>
            <li>7.7.4 Poszkodowany odgrywa obrażenia nieadekwatne do powodu BW.</li>
          </ul>
          <p>7.8 EMS ma nakaz odgrywania, także głosowego, komendy narracyjne uznawane są jako dodatek który pomaga, “wciągnąć się” w świat gry. </p>
          <p>7.9 Zakazuje się odbijania jeśli medyk zainicjuje udzielanie pomocy.</p>
          <p>7.10 Medycy mają nakaz udzielania profesjonalnej pomocy – w inny wypadku jest to uznawane za LowRP. </p>
          <p>7.11 Zakaz przekazywania itemów medycznych. Medyk ma prawo użyć itemu medycznego na własne potrzeby, bądź innego medyka.</p>
          <p>7.12 Każdy członek frakcji ma obowiązek stosować się do wewnętrznego regulaminu frakcji.</p>
          <p>7.14 Zabronione jest przechodzenie z frakcji do crime od razu po jej opuszczeniu. Należy odczekać minimum 1 dzień</p>
          <p>7.15 Zabronione jest posiadanie tatuaży na twarzy będąc w AMR, SFPD, NPS, SFFD itp. oraz pracownicy gazety/telewizji jeśli występują przed kamerą.</p>

          <hr className="neon-gradient-shadow mb-6" />

          <h3 className="text-2xl font-bold text-white mt-6">§8. FIRMY</h3>
          <p>8.1 SFPD ma prawo wjechać oraz przeszukać teren siedziby firmy tylko, gdy zarząd firmy jest poszukiwany za duże przestępstwo.</p>
          <p>8.2 Każdy członek firmy ma obowiązek stosować się do wewnętrznego regulaminu firmy.</p>
          <p>8.3 Zakazuje się wynoszenia przedmiotów należących do firmy.</p>
          <p>8.4 Podczas wykonywania pracy masz obowiązek poruszać się firmowym pojazdem!</p>
          <p>8.5 Burmistrz ma prawo nadać dofinansowanie dla firm przez niego wybranych, jeśli miasto posiada na to środki.</p>
          <p>8.6 Działalnościami gospodarczymi zajmuje się wydział wewnętrzny w Urzędzie Miasta.</p>

          <hr className="neon-gradient-shadow mb-6" />

          <h3 className="text-2xl font-bold text-white mt-6">§9. CRIME</h3>
          <p>9.1 Zabrania się okradania, zabijania oraz porywania medyków na służbie jeśli medyków jest mniej niż 4 oraz jeśli sami nie rozpoczną inicjacji.</p>
          <p>9.2 Odbicie osób zatrzymanych może odbyć się dopiero w momencie, gdy konwój przejedzie 500 m oraz maksymalnie 200 m przed terenem więzienia.</p>
          <p>9.3 Porwanie obywateli może nastąpić gdy na służbie jest minimum 2 policjantów. Wyjątkiem są porwania z terenu Red Zonów oraz rozpoczęcie inicjacji ze strony przeciwnika.</p>
          <p>9.4 Porywać policjantów można od 4 funkcjonariuszy na służbie.</p>
          <ul className="list-disc pl-6">
            <li>9.4.1 Porwani policjanci nie liczą się do limitu akcji. Po odbiciu/odzyskaniu nie mogą brać oni udziału w akcji powyżej limitu.</li>
          </ul>
          <p>9.5 Porywać dla okupu można od 4 funkcjonariuszy na służbie.</p>
          <ul className="list-disc pl-6">
            <li>9.5.1 Maksymalny okup jakiego można żądać za porwanego:</li>
            <ul className="list pl-6">
              <li>Cywila: <strong>7,500$</strong></li>
              <li>Policjanta <strong>15 000 $</strong></li>
              <li>High Command departamnetu <strong>25 000 $</strong></li>
              <li>Medyk <strong>12 500 $</strong></li>
            </ul>
            <li>9.5.2 Aby żądać okupu za kogokolwiek trzeba mieć do tego dobre podłoże RP (Brak kasy to nie jest żadne podłoże)</li>
          </ul>
          <p>9.6 Zakaz ucieczki na GreenZone i/lub RedZone, jeżeli gracz po wcześniejszej inicjacji ucieka na zieloną i/lub czerwoną strefę masz prawo kontynuować akcję RP.</p>
          <p>9.7 Zakazuje się dodawania, edytowania, usuwania kontaktów w skradzionych kartach SIM.</p>
          <p>9.8 Zakazuje się okradania kont bankowych.</p>
          <p>9.9 Zakazuje się popełniania przestępstw w strojach prac legalnych.</p>
          <p>9.10 Zakazuje się popełniania przestępstw w autach prac legalnych.</p>
          <p>9.11 Limit na agresywną akcję dla zaakceptowanych organizacji przestępczych wynosi 9 osób, natomiast dla niezaakceptowanych wynosi 4. Limit departamnetów porządkowych wynosi 10 osób.</p>
          <ul className="list-disc pl-6">
            <li>9.11.1 Limit osób robiących “napad” dla organizacji nie zaakceptowanych wynosi 4</li>
          </ul>
          <p>9.12 Limity ilościowe policji oraz Crime znajdują się tabelce poniżej:</p>
          <span>(Limity osób niżej obowiązują do zakończenia akcji).</span>
          <table className="table-auto border-collapse w-full text-white">
            <thead>
              <tr className="bg-gray-800 text-yellow-400">
                <th className="px-4 py-2 text-left font-bold italic">Napad :</th>
                <th className="px-4 py-2">Bank</th>
                <th className="px-4 py-2">Jubiler</th>
                <th className="px-4 py-2">Kasetka</th>
                <th className="px-4 py-2">Akcja Agresywna</th>
                <th className="px-4 py-2">Akcja Hostylna</th>
              </tr>
            </thead>
            <tbody>
              <tr className="hover:bg-gray-700">
                <td className="border border-gray-600 px-4 py-2">Maksymalna ilość Crime</td>
                <td className="border border-gray-600 px-4 py-2">6</td>
                <td className="border border-gray-600 px-4 py-2">4</td>
                <td className="border border-gray-600 px-4 py-2">2</td>
                <td className="border border-gray-600 px-4 py-2">6</td>
                <td className="border border-gray-600 px-4 py-2">5</td>
              </tr>
              <tr className="hover:bg-gray-700">
                <td className="border border-gray-600 px-4 py-2">Maksymalna ilość Law Enforcement</td>
                <td className="border border-gray-600 px-4 py-2">10</td>
                <td className="border border-gray-600 px-4 py-2">8</td>
                <td className="border border-gray-600 px-4 py-2">5</td>
                <td className="border border-gray-600 px-4 py-2">8</td>
                <td className="border border-gray-600 px-4 py-2">7</td>
              </tr>
            </tbody>
          </table>
          <p>9.13 Zakazuje się porywania osób z terenów Osiek Gangów oraz GZ.</p>
          <p>9.14 Maksymalna ilość osób w organizacji przestępczej to 35 a gangu ulicznego 40.</p>
          <p>9.15 Lider danej organizacji przestępczej bierze całkowitą odpowiedzialność za wszystkich członków danej organizacji.</p>
          <p>9.16 Zarejestrowane organizacje mają prawo, aby prowadzić ze sobą wojnę. Wojna nie może być zapoczątkowana z byle jakiego powodu i musi mieć mocne podłoże roleplay.</p>
          <p>9.17 Po rozpoczęciu wojny dozwolone są wszelkie formy ataku na inny gang.</p>
          <p>9.18 Zakazuje się odgrywania organizacji przestępczej w sposób: porywanie losowych osób i zabijanie / okradanie ich. Jako organizacja przestępcza macie bardzo duże pole do popisu i odgrywania kreatywnych akcji. </p>
          <p>9.19 Akcje agresywną można rozpocząć po poprawnym zainicjowaniu akcji. Inicjacja dzieli się na werbalną oraz niewerbalną, gdzie inicjacja werbalna oznacza zakomunikowanie przeciwnikowi rozpoczęcie akcji agresywnej, a inicjacja niewerbalna oznacza wystrzelenie minimum 2 pocisków w powietrze. Zarejestrowane organizacje przestępcze oraz policjia, czyt. Law Enforcement mają prawo do inicjacji grupowej, to znaczy, że zainicjowanie strzelaniny przez jedną osobę również rozpoczyna strzelaninę dla reszty organizacji.</p>
          <p>9.20 Okraść LEO czyt. Law Enforcement Officer można tylko w poniżej wymienionych przypadkach:</p>
          <ul className="list-disc pl-6">
            <li>9.20.1 Napad na Bank</li>
            <li>9.20.2 Napad na Jubilera</li>
            <li>9.20.3 Wjazd frontalny przeprowadzony przez PD, NPS</li>
            <li>9.20.4 Policja zainicjuje agresywną akcję RolePlay</li>
            <li>9.20.5 Konwój</li>
          </ul>
          <p>9.21 Zakazuje się sprzedaży narkotyków na terenie siedzib organizacji, Lotniskach oraz w miejscach takich jak kino czy dachy. </p>
          <ul className="list-disc pl-6">
            <li>9.21.1 Organizacje przestępcze mogą sprzedawać narkotyki dopiero po oddaleniu się na odległość minimum 2.5 km od siedziby.</li>
          </ul>
          <p>9.22 Strona atakowana nie ma limitu podczas akcji agresywnej np. wjazd na ośkę, siedzibę organizacji.</p>
          <p>9.23 Zakazuje się odbijania napadów. (Wyjątkiem są służby porządkowe)</p>
          <p>9.24 Podczas przeprowadzania wjazdu frontalnego przez SFPD, NPS na siedzibę organizacji przestępczej obowiązują poniższe limity</p>
          <ul className="list-disc pl-6">
            <li>9.24.1 Limit funkcjonariuszy na wjazdy na siedziby gangów, karteli oraz mafii wynosi 10.</li>
            <li>9.24.2 Limit funkcjonariuszy na wjazdy na siedziby gangów ulicznych wynosi 14.</li>
          </ul>
          <p>9.25 Limit broni długich na akcję agresywną = 5 długie (obowiązuje SFPD, NPS)</p>
          <p>9.26 Współpraca dwóch organizacji przestępczych podczas odbijania konwoju jest zakazana. Nie dotyczy Mafii.</p>
          <ul className="list-disc pl-6">
            <li>9.26.1 Wyjątkiem jest konwój szefa organizacji przestępczej. W takim przypadku dozwolona jest współpraca między organizacjami przestępczymi.</li>
          </ul>
          <p>9.27 Po CK postaci, do organizacji można dołączyć po 5 dniach od daty CK</p>
          <p>9.28 Policja ma prawo dołączyć na trzeciego do akcji przejmowania biznesu gdy dostaną powiadomienia o oddanych strzałach na terenie biznesu. (Należy używać tłumików)</p>
          <p>9.29 Zakazuje się odbijania osoby zatrzymanej która jest transportowana z miejsca zatrzymania do siedziby Sądu i odwrotnie.</p>
          <p>9.30 Zakaz stania, czekania, kampienia, wcelowywania się na miejscach do rozpoczęcia napadów.</p>
          <p>9.31 Zakaz odbijania policyjnego konwoju podczas gdy jedzie do szpitala z zatrzymanymi</p>
          <p>9.33 Zakazuje się używania powiedzonek/słówek wymienionych w słowniczku</p>
          <p>9.34 Jeśli osoba konwojowana dostanie crasha to policja kontynuuje konwój bez względu na brak osoby konwojowanej w środku</p>
          <ul className="list-disc pl-6">
            <li>9.34.1 Osoba konwojowana po crashu na serwer może wejść dopiero po zakończeniu konwoju.</li>
          </ul>

          <hr className="neon-gradient-shadow mb-6" />



        </div>
      </div>
    </div>
  );
}

export default Regulamin;
