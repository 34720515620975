import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import Regulamin from './pages/Regulamin';
import Slowniczek from './pages/Slowniczek';
import About from './pages/Abaut';
import Wazne from './pages/Wazne';
import AptApi from './pages/AbtApi';
import ApiDocs from './pages/Api-docs';
import Glosowanie from './urzad/Glosowanie';
import GlosowanieP from './urzad/VotingPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Get the root element where the app will be rendered
const container = document.getElementById('root');

// Use the new createRoot API for React 18
const root = createRoot(container);

// Render the application
root.render(
    <Router>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/regulamin" element={<Regulamin />} />
            <Route path="/slowniczek" element={<Slowniczek />} />
            <Route path="/ostanie" element={<About />} />
            <Route path="/wazne" element={<Wazne />} />
            <Route path="/api/about" element={<AptApi />} />
            <Route path="/api-docs" element={<ApiDocs />} />
            <Route path="/urzad/glosowanie" element={<Glosowanie />} />
            <Route path="/urzad/glosowanie/form" element={<GlosowanieP />} />
        </Routes>
    </Router>
);
