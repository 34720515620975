import React, { useState } from 'react';
import { FaServer, FaRocket, FaBolt, FaSyncAlt, FaLock, FaShieldAlt, FaCogs, FaRobot, FaCat } from 'react-icons/fa';
import './DocsPage.css'; // Plik stylizacji

function DocsPage() {
    const [showRocket, setShowRocket] = useState(false); // State to control rocket animation

    // Function to trigger rocket animation
    const handleRocketClick = () => {
        setShowRocket(true);
        setTimeout(() => setShowRocket(false), 2000); // Hide the rocket after 2 seconds
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-900 to-black text-white p-10 relative">
            {showRocket && <div className="rocket-animation">🚀</div>} {/* Rocket animation */}

            <div className="container mx-auto max-w-6xl">
                {/* Header */}
                <header className="text-center mb-12">
                    <h1 className="text-6xl font-extrabold text-white mb-4 animate-bounce">⚡ Light API - Najlepsze API na Polsim Rp</h1>
                    <p className="text-xl text-gray-200">Bezpieczne i wydajne API dla administracji Light RP</p>
                </header>

                {/* Animacja przesyłania danych */}
                <div className="data-flow-container mb-16">
                    <div className="frontend animate-flow">👨‍💼 Administracja</div>
                    <div className="arrow">➡️</div>
                    <div className="api animate-flow">🌐 Light API</div>
                    <div className="arrow">➡️</div>
                    <div className="discord animate-flow">🤖 Bot Discord</div>
                </div>

                {/* Section 1 */}
                <section id="co-to-jest" className="mb-10">
                    <h2 className="text-4xl font-bold mb-4">
                        <FaServer className="inline mr-2 text-green-500" /> Co to jest API?
                    </h2>
                    <p className="text-lg">
                        API, czyli <strong>Interfejs Programowania Aplikacji</strong> (z ang. Application Programming Interface), to sposób, w jaki różne systemy mogą się ze sobą komunikować. Dzięki API, nasza strona internetowa może rozmawiać z botem na Discordzie, wysyłając dane i zlecenia w czasie rzeczywistym. W Light RP API służy do zautomatyzowania wielu zadań i zarządzania serwerem Discord w bezpieczny sposób.
                    </p>
                </section>

                {/* Section 2 */}
                <section id="jak-dziala" className="mb-10">
                    <h2 className="text-4xl font-bold mb-4">
                        <FaRocket className="inline mr-2 text-blue-400" /> Jak działa Light API?
                    </h2>
                    <p className="text-lg">
                        Light API pozwala administracji na przesyłanie danych z panelu administracyjnego prosto do bota na Discordzie. Działa to w kilku prostych krokach:
                    </p>
                    <ul className="list-disc pl-6 mt-4 text-lg">
                        <li>Klikasz odpowiedni przycisk lub wypełniasz formularz w panelu administracyjnym.</li>
                        <li>Panel przesyła dane do Light API.</li>
                        <li>API przekazuje te dane do bota na Discordzie, który wykonuje odpowiednią akcję (np. wysyłanie wiadomości na kanał).</li>
                    </ul>
                    <p className="text-lg mt-4">
                        Wszystko to dzieje się automatycznie i w czasie rzeczywistym, dzięki czemu zarządzanie serwerem Discord jest łatwiejsze i szybsze.
                    </p>
                </section>

                {/* Section 3 */}
                <section id="ping-api" className="mb-10">
                    <h2 className="text-4xl font-bold mb-4">
                        <FaBolt className="inline mr-2 text-yellow-400" /> Jak szybkie jest nasze API?
                    </h2>
                    <p className="text-lg">
                        Nasze API jest wyjątkowo szybkie (<span className="text-blue-400 cursor-pointer" onClick={handleRocketClick}>Dlatego Light od prędkości</span>). Średnie opóźnienie (czyli czas, jaki potrzebuje system na odpowiedź) wynosi zaledwie <strong>30-50 ms</strong>. Dzięki temu administracja może działać bez opóźnień i natychmiastowo zarządzać serwerem Discord.
                    </p>
                    <div className="bg-gray-800 p-4 rounded-lg mt-4">
                        <h4 className="text-xl font-semibold">Średni czas odpowiedzi (Ping):</h4>
                        <p className="text-2xl font-bold">
                            30 - 50 ms
                            <span className="blinking-dot ml-2">•</span> {/* Migająca kropka */}
                        </p>
                    </div>
                </section>

                {/* Section 4 */}
                <section id="bezpieczenstwo" className="mb-10">
                    <h2 className="text-4xl font-bold mb-4">
                        <FaShieldAlt className="inline mr-2 text-red-400" /> Jak bezpieczne jest Light API?
                    </h2>
                    <p className="text-lg">
                        Bezpieczeństwo jest naszym priorytetem. Każda operacja wykonywana za pośrednictwem Light API jest odpowiednio zabezpieczona. Korzystamy z szyfrowania, aby upewnić się, że dane są przesyłane w sposób bezpieczny i są chronione przed nieuprawnionym dostępem.
                    </p>
                </section>

                {/* Section 5 */}
                <section id="zarzadzanie" className="mb-10">
                    <h2 className="text-4xl font-bold mb-4">
                        <FaCogs className="inline mr-2 text-orange-400" /> W czym pomaga API?
                    </h2>
                    <p className="text-lg">
                        Light API zostało zaprojektowane tak, aby było łatwe w użyciu dla administracji Light RP. Wszystkie operacje są zautomatyzowane i dostępne za pomocą prostych interfejsów. Wystarczy jedno kliknięcie, aby przesłać polecenie do bota Discord, który wykona odpowiednie zadanie.
                    </p>
                    <p className="text-lg mt-4">
                        Przykłady możliwych akcji:
                    </p>
                    <ul className="list-disc pl-6 mt-4 text-lg">
                        <li>Wysyłanie banów, warnów na serwer Discord.</li>
                        <li>Automatyczne zarządzanie rolami i użytkownikami.</li>
                        <li>Wysyłanie komend do api ERLC.</li>
                    </ul>
                </section>

                {/* Section 6 */}
                <section id="developerzy" className="mb-10">
                    <h2 className="text-4xl font-bold mb-4">
                        <FaRobot className="inline mr-2 text-cyan-400" /> Twórcy Light API
                    </h2>
                    <p className="text-lg">
                        Light API zostało stworzone przez <strong>vi_vio</strong>.
                        Pracujemy nad tym, aby API było nie tylko szybkie, ale także elastyczne i bezpieczne, umożliwiając administracji Light RP pełną kontrolę nad serwerem.
                    </p>
                    <FaCat />
                </section>
            </div>
        </div>
    );
}

export default DocsPage;
