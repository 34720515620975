import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function VotePage() {
    const [user, setUser] = useState(null);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [age, setAge] = useState('');
    const [candidate, setCandidate] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch('http://localhost:5000/api/v1/vote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name, surname, age, candidate }),
        });

        const data = await response.json();
        if (data.success) {
            toast.success(data.success);
        } else {
            toast.error(data.error);
        }
    };


    return (
        <div>
            <ToastContainer />
            <h1>Formularz głosowania</h1>
            <form onSubmit={handleSubmit}>
                <input value={name} onChange={(e) => setName(e.target.value)} placeholder="Imię" />
                <input value={surname} onChange={(e) => setSurname(e.target.value)} placeholder="Nazwisko" />
                <input value={age} onChange={(e) => setAge(e.target.value)} placeholder="Wiek" />
                <select value={candidate} onChange={(e) => setCandidate(e.target.value)}>
                    <option value="">Wybierz kandydata</option>
                    <option value="Kandydat A">Kandydat A</option>
                    <option value="Kandydat B">Kandydat B</option>
                </select>
                <button type="submit">Oddaj głos</button>
            </form>
        </div>
    );
}

export default VotePage;
