import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logoLightRP from '.././ikona_light.png'; // Ścieżka do Twojego logo
import Loader from "../Loader";
import { FaGlobe, FaDiscord, FaLink } from 'react-icons/fa'; // Importujemy ikony

function WazneLinki() {
    const [fadeText, setFadeText] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoaded(true);
        }, 999);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeText((prev) => !prev);
        }, 10000); // Miganie co 10 sekund

        return () => clearInterval(interval); // Czyszczenie po unmount
    }, []);

    if (!isLoaded) {
        return <Loader />;
    }

    return (
        <div className="min-h-screen bg-dark text-light p-10 relative">
            {/* Logo i napis Light RP z efektem */}
            <div className="absolute top-6 left-6 flex items-center">
                <img
                    src={logoLightRP}
                    alt="Light RP Logo"
                    className="w-16 h-16 animate-pulse drop-shadow-[0_0_15px_rgba(255,221,0,0.8)]"
                />
                <h1
                    className={`ml-4 text-2xl font-bold ${fadeText ? 'text-yellow-400 transition-colors duration-500' : 'text-white'
                        }`}
                >
                    Light RP
                </h1>
            </div>

            {/* Link do Strony Głównej */}
            <div className="absolute top-6 right-6">
                <Link to="/" className="px-8 py-3 bg-transparent border-2 border-light text-light font-bold rounded-full hover:bg-light hover:text-dark transition duration-300 ease-in-out transform hover:scale-105">
                    Strona Główna
                </Link>
            </div>

            {/* Sekcja Ważne Linki */}
            <div className="container mx-auto mt-20">
                <h2 className="text-4xl font-bold text-yellow-400 mb-6">Ważne Linki</h2>
                {/* Animowany neonowy pasek z cieniem */}
                <hr className="neon-gradient-shadow mb-6" />

                <div className="text-lg text-gray-300 space-y-6">
                    <p>Oto ważne linki związane z naszymi podmiotami:</p>

                    {/* <div className="flex flex-col space-y-4">

                        <a href="https://policeroleplay.community" target="_blank" rel="noopener noreferrer"
                            className="flex items-center justify-center px-6 py-3 bg-yellow-400 text-dark font-bold rounded-full hover:bg-yellow-500 transition duration-300 ease-in-out transform hover:scale-105">
                            <FaGlobe className="mr-2" /> Strona Główna Policja Roleplay
                        </a>


                        <a href="https://discord.com/invite/lightrp" target="_blank" rel="noopener noreferrer"
                            className="flex items-center justify-center px-6 py-3 bg-yellow-400 text-dark font-bold rounded-full hover:bg-yellow-500 transition duration-300 ease-in-out transform hover:scale-105">
                            <FaDiscord className="mr-2" /> Oficjalny Discord Light RP
                        </a>


                        <a href="https://policeroleplay.community/join/Light" target="_blank" rel="noopener noreferrer"
                            className="flex items-center justify-center px-6 py-3 bg-yellow-400 text-dark font-bold rounded-full hover:bg-yellow-500 transition duration-300 ease-in-out transform hover:scale-105">
                            <FaLink className="mr-2" /> Dołącz do Light RP
                        </a>


                        <a href="https://sites.google.com/view/lightrp-pl/linki-do-naszych-podmiot%C3%B3w" target="_blank" rel="noopener noreferrer"
                            className="flex items-center justify-center px-6 py-3 bg-yellow-400 text-dark font-bold rounded-full hover:bg-yellow-500 transition duration-300 ease-in-out transform hover:scale-105">
                            <FaLink className="mr-2" /> Więcej Ważnych Linków
                        </a>
                    </div> */}
                </div>

                <hr className="neon-gradient-shadow mt-6" />
            </div>
        </div>
    );
}

export default WazneLinki;
