import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logoLightRP from '.././ikona_light.png'; // Ścieżka do Twojego logo
import Loader from "../Loader";

function SanFrancisco() {
    const [fadeText, setFadeText] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoaded(true);
        }, 999);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setFadeText((prev) => !prev);
        }, 10000); // Miganie co 10 sekund

        return () => clearInterval(interval); // Czyszczenie po unmount
    }, []);

    if (!isLoaded) {
        return <Loader />;
    }

    return (
        <div className="min-h-screen bg-dark text-light p-10 relative">
            {/* Logo i napis Light RP z efektem */}
            <div className="absolute top-6 left-6 flex items-center">
                <img
                    src={logoLightRP}
                    alt="Light RP Logo"
                    className="w-16 h-16 animate-pulse drop-shadow-[0_0_15px_rgba(255,221,0,0.8)]"
                />
                <h1
                    className={`ml-4 text-2xl font-bold ${fadeText ? 'text-yellow-400 transition-colors duration-500' : 'text-white'
                        }`}
                >
                    Light RP
                </h1>
            </div>

            {/* Link do Strony Głównej */}
            <div className="absolute top-6 right-6">
                <Link to="/" className="px-8 py-3 bg-transparent border-2 border-light text-light font-bold rounded-full hover:bg-light hover:text-dark transition duration-300 ease-in-out transform hover:scale-105">
                    Strona Główna
                </Link>
            </div>

            {/* Sekcja San Francisco */}
            <div className="container mx-auto mt-20">
                <h2 className="text-4xl font-bold text-yellow-400 mb-6">Informacje o San Francisco</h2>
                {/* Animowany neonowy pasek z cieniem */}
                <hr className="neon-gradient-shadow mb-6" />

                <div className="text-lg text-gray-300 space-y-6">
                    <h3 className="text-2xl font-bold text-white">Informacje ogólne</h3>
                    <p>
                        Jest to miasto i hrabstwo w stanie Kalifornia w Stanach Zjednoczonych, położone na półwyspie San Francisco,
                        otoczonym przez Ocean Spokojny na zachodzie, zatokę San Francisco na wschodzie i cieśninę Golden Gate na północy.
                        Jest to czwarte pod względem liczby ludności miasto w Kalifornii i trzynaste w całym kraju. Jego liczba ludności wynosi około 850 tys.
                    </p>

                    <h3 className="text-2xl font-bold text-white">Historia i Klimat</h3>
                    <p>
                        W 1906 roku San Francisco zostało w większej części zniszczone podczas trzęsienia ziemi i pożarów, które były jego skutkiem.
                        Po kataklizmie zostało szybko odbudowane. Podczas II wojny światowej San Francisco było punktem ekspedycyjnym dla tysięcy żołnierzy
                        walczących na Pacyfiku i ważnym portem wypadowym operującej na tym oceanie floty.
                    </p>
                    <p>
                        Obecnie San Francisco jest dużym ośrodkiem turystycznym znanym ze swoich atrakcji, między innymi mostu nad Złotymi Wrotami,
                        więzienia Alcatraz, Coit Tower, tramwajów linowych, fabryki czekolady Ghirardelli﻿, czy chińskiej dzielnicy﻿.
                    </p>
                    <p>
                        Charakterystyczne dla miasta są jego strome wzgórza i ulice oraz mieszanina stylów architektonicznych, od wiktoriańskiego do postmodernizmu.
                        Miasto słynie także ze swojego zróżnicowania etnicznego, uniwersytetów oraz wyodrębnionych i wpływowych społeczności m.in azjatyckiej oraz latynoamerykańskiej.
                    </p>

                    <h3 className="text-2xl font-bold text-white">Klimat</h3>
                    <p>
                        San Francisco, jak i cała Kalifornia, leży w strefie klimatu śródziemnomorskiego, który cechują łagodne zimy z licznymi opadami i suche lata.
                        Dodatkowy wpływ na klimat miasta ma woda, która otacza je z trzech stron: powoduje ona zmniejszone wahania klimatu,
                        co owocuje umiarkowanymi temperaturami o małym zróżnicowaniu rocznym.
                    </p>
                    <p>
                        Połączenie zimnego powietrza znad oceanu i gorących jego mas znad lądu owocuje charakterystycznymi dla miasta mgłami, które wiosną i wczesnym latem
                        spowijają zachodnią część miasta przez cały dzień. Mgła jest mniej widoczna we wschodniej części miasta i zwykle występuje tam późnym latem i jesienią,
                        gdy temperatury są najwyższe.
                    </p>
                    <p>
                        Średnia najwyższa temperatura w San Francisco to 21 °C. Najwyższa zanotowana temperatura w San Francisco to 39 °C. Zimy w mieście są łagodne
                        z najwyższymi temperaturami dziennymi sięgającymi 15 °C. Najniższe rzadko kiedy osiągają 0 °C. Od maja do września, w okresie letnim,
                        jest bardzo sucho, ale z kolei od listopada aż do marca opady deszczu są bardzo częste. Opady śniegu są niezwykle rzadkie.
                    </p>
                    <p>
                        Wysokie wzgórza w centrum miasta są odpowiedzialne za 20% różnicę w rocznych opadach pomiędzy różnymi częściami miasta.
                        Przeciętnie wschodnia część miasta jest bardziej słoneczna.
                    </p>
                </div>

                <hr className="neon-gradient-shadow mt-6" />
            </div>
        </div>
    );
}

export default SanFrancisco;
