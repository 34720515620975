import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Loader from './Loader'; // Import Loader
import logoLightRP from './ikona_light.png';
import coverImage from './cover.png';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 999);

    return () => clearTimeout(timer);
  }, []);

  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const joinRP = () => {
    window.location.href = 'https://policeroleplay.community/join/Light';
  };

  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);

  const textArray = ['Najlepszy server Role Play San Francisco.', 'Jedyny taki server w polsce.', 'Dołącz już dziś i rozpocznij przygodę.'];
  const period = 1500;

  useEffect(() => {
    const handleTyping = () => {
      const i = loopNum % textArray.length;
      const fullText = textArray[i];

      setText(isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1));

      if (!isDeleting && text === fullText) {
        setTimeout(() => setIsDeleting(true), period);
      } else if (isDeleting && text === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setTypingSpeed(500);
      } else {
        setTypingSpeed(isDeleting ? 50 : 150);
      }
    };

    const timer = setTimeout(handleTyping, typingSpeed);
    return () => clearTimeout(timer);
  }, [text, isDeleting, typingSpeed, loopNum, textArray]);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div className="min-h-screen bg-dark text-light flex items-center justify-center relative">
      {/* Obrazek z lewej strony */}
      <div className="absolute top-0 left-0 h-full w-1/2 overflow-hidden">
        <div className="relative h-full">
          <img
            src={coverImage}
            alt="Cover"
            className="object-cover h-full w-full transform skew-x-3"
          />
        </div>
      </div>

      <div className="text-center ml-auto w-1/2 p-10 z-10">
        <h1 className="text-5xl font-extrabold mb-6 tracking-wide">Light RP</h1>
        <p className="text-lg mb-8">{text}<span className="blinking-cursor">|</span></p>
        <div className="space-x-4">
          {/* Zmieniony przycisk */}
          <button
            onClick={joinRP}
            className="px-8 py-3 button-yellow font-bold rounded-full shadow-lg hover:bg-yellow-400 transition duration-300 ease-in-out transform hover:scale-105"
          >
            Dołącz do RP
          </button>
          <button className="px-8 py-3 bg-transparent border-2 border-light text-light font-bold rounded-full hover:bg-light hover:text-dark transition duration-300 ease-in-out transform hover:scale-105" to="https://discord.gg/XmryrupNRp">
            Dołącz na Discord
          </button>
        </div>
      </div>

      <div className="absolute top-6 right-6 cursor-pointer" onClick={() => setMenuOpen(!menuOpen)}>
        <img
          src={logoLightRP}
          alt="Logo Light RP"
          className="w-16 h-16 animate-spin-slow drop-shadow-[0_0_15px_rgba(255,221,0,0.8)]"
        />

        {/* Dodany tekst "Kliknij tu" ze strzałką */}
        <p className="text-yellow-401 font-bold mt-2 animate-bounce">Kliknij tu <span className="arrow">^</span></p>
      </div>

      {menuOpen && (
        <div ref={menuRef} className="absolute top-20 right-6 bg-dark p-6 rounded-md shadow-lg z-50">
          <ul className="space-y-4">
            <li className="text-yellow-400 hover:text-yellow-300 hover:bg-yellow-600 p-2 rounded-md transition">
              <Link to="/regulamin">Regulamin</Link>
            </li>
            {/* <li className="text-yellow-400 hover:text-yellow-300 hover:bg-yellow-600 p-2 rounded-md transition">
              <a href="#">O nas</a>
            </li>
            <li className="text-yellow-400 hover:text-yellow-300 hover:bg-yellow-600 p-2 rounded-md transition">
              <a href="#">FAQ</a>
            </li> */}
            <li className="text-yellow-400 hover:text-yellow-300 hover:bg-yellow-600 p-2 rounded-md transition">
              <a href="/slowniczek">Słowniczek</a>
            </li>
            <li className="text-yellow-400 hover:text-yellow-300 hover:bg-yellow-600 p-2 rounded-md transition">
              <a href="/ostanie">O San Francisco</a>
            </li>
            <li className="text-yellow-400 hover:text-yellow-300 hover:bg-yellow-600 p-2 rounded-md transition">
              <a href="/wazne">Ważne Linki</a>
            </li>
            <li className="text-yellow-400 hover:text-yellow-300 hover:bg-yellow-600 p-2 rounded-md transition">
              <a href="/api/about">O API</a>
            </li>
            {/* <li className="text-yellow-400 hover:text-yellow-300 hover:bg-yellow-600 p-2 rounded-md transition">
              <a href="#">Ściania chwały</a>
            </li>  */}
          </ul>
        </div>
      )}
    </div>
  );
}

export default App;
